import React from "react";
import "./Home.scss";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div id="home">
      <div className="home-wrapper container">
        <section className="first-section">
          <div className="first">
            <div className="home-header">
              <div className="logo">
                <img src="/blue_logo_transparent.png" alt="logo" />
              </div>
            </div>

            <div className="home-text">
              <h4>
                <b>Gear up for some fleet fun!</b>
              </h4>
              <p>
              Get ready for some exciting fleet fun! This is your opportunity to be recognised for your fleet electrification successes. Let's celebrate all fleets that are helping to increase the use of zero-emission vehicles. There are no costs involved, only awards to be won. You’ve got to be in it to win it.
              </p>
              <h4 className="how-works">
                <b>So, how does it work?</b>
              </h4>
              <p>
              To take part, simply click "enter now" below and fill out a brief entry form.
              </p>
              <h4 className="how-works">
                <b>Why should you participate?</b>
              </h4>
              <p>
              Enter for the chance to be recognised as a leader in your industry. Receive a monthly email with a digital award certificate and stand a chance to be crowned the UK's largest EV fleet at the GREENFLEET 2024 awards. Winners will also enjoy exclusive press coverage and publicity from our partners at GREENFLEET.
              </p>
            </div>

            <div className="get-started">
              <button onClick={() => navigate("/submit-entry")}>
                Enter Now
              </button>
            </div>

            <div className="car-movement">
              <div className="car">🚗</div>
              <div className="racecar">🏎️</div>
            </div>
          </div>

          <div className="scroll">
            <p>Frequently asked questions</p>
            <div className="arrow bounce"></div>
          </div>
        </section>

        <section className="second">
          <div>
            <h4>How can businesses participate in the Fleet Race?</h4>
            <p>
              To enter, click ‘Enter Now' above or{" "}
              <a href="/submit-entry">click here</a>, fill out the form, and we
              will take care of the rest.
            </p>

            <h4>Is there any cost to participate?</h4>
            <p>
              Nope, it is absolutely free! We want to highlight the businesses
              and individuals who are making progress in this brilliant sector.
            </p>

            <h4>How’s it measured?</h4>
            <p>
              We measure fleets based on their total number of electric
              vehicles. Don't worry if you have a small fleet, we have different
              categories to celebrate fleets of all sizes.
            </p>
            <h4>Do I have to enter every month?</h4>
            <p>
              No need to enter every month. Submit once and you're in for the
              monthly race and end-of-year awards. To update fleet numbers,
              email{" "}
              <a href="mailto:hello@rightcharge.co.uk">
                hello@rightcharge.co.uk
              </a>
              .
            </p>

            <h4>What are the award categories?</h4>
            <p>
              Two national awards will be presented to companies that have the
              largest fleets of electric cars and vans, and there will be one
              award for the most electrified company in each industry. Moreover,
              an award will be given to the most interesting electrified
              vehicle. It's important to note that you will be automatically
              placed into one of these award categories, and there's no need for
              you to select one yourself.
            </p>

            <h4>What are the awards?</h4>
            <p>
              Each month, the leading fleets will receive a digital award
              certificate. Winners will also receive exclusive press coverage
              and publicity from our partners at GREENFLEET, as well as £100 in
              Rightcharge Credit. The end-of-year awards are currently being
              finalised.
            </p>

            <h4>What is Rightcharge Credit?</h4>
            <p>
              Rightcharge credit can be used for our brand-new electric fuel
              card subscription.
            </p>
          </div>
          <div className="rules">
            <h4>Rules for entry</h4>
            <ul>
              <li>
                The Electric Fleet Race decides whether an entry is valid for a
                particular category.
              </li>
              <li>
                The Electric Fleet Race's decisions are final. The Electric
                Fleet Race will not discuss the Award.
              </li>
              <li>
                The Electric Fleet Race, the judges and partners of The Electric
                Fleet Race shall not be held responsible for any legal
                proceedings arising from the entries or the statements made
                therein, nor can they accept responsibility for the veracity of
                statements made by entrants, although every care will be taken
                to check entries.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
