export const getPasswordStrength = (pwd) => {
  // Implement your password strength logic here
  // For simplicity, let's assume a password is strong if it meets your criteria
  const hasUpperCase = /[A-Z]/.test(pwd);
  const hasLowerCase = /[a-z]/.test(pwd);
  const hasNumber = /\d/.test(pwd);
  const isLengthValid = pwd.length >= 8;

  if (hasUpperCase && hasLowerCase && hasNumber && isLengthValid) {
    return "strong";
  } else if ((hasUpperCase || hasLowerCase || hasNumber) && isLengthValid) {
    return "medium";
  } else {
    return "weak";
  }
};

export const showPassword = () => {
  const password = document.getElementById("password");
  const confirmPassword = document.getElementById("confirm-password");
  if (password.type === "password" && confirmPassword.type === "password") {
    password.type = "text";
    confirmPassword.type = "text";
  } else {
    password.type = "password";
    confirmPassword.type = "password";
  }
};

export const doPasswordsMatch = (pwd, c) => {
  return pwd === c && pwd !== "";
};

export const isBusinessEmail = (email) => {
  // Define a regular expression for excluding common personal email domains
  const personalEmailRegex =
    /@(gmail\.com|googlemail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com|icloud\.com|live\.com|msn\.com|mail\.com|me\.com|inbox\.com|gmx\.com|zoho\.com|protonmail\.com)$/i;

  // Extract the domain from the email address
  const [, domain] = email.match(/@(.+)$/);

  // Check if the domain is not in the personal email regex
  return !personalEmailRegex.test(domain);
};
