import React from "react";
import "./Footer.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <a href="https://www.rightcharge.co.uk">
        Rightcharge &#169; {currentYear}
      </a>
      <span>|</span>
      <a href="https://rightcharge.co.uk/privacy-policy/">Privacy Policy</a>
      <span>|</span>
      <a href="https://rightcharge.co.uk/terms/">Terms & Conditions</a>
    </div>
  );
};

export default Footer;
