export const industriesOptions = [
  { label: "Please select your industry", value: '' },
  { label: "Agriculture", value: "Agriculture" },
  { label: "Automotive", value: "Automotive" },
  { label: "Banking", value: "Banking" },
  { label: "Business Services", value: "BusinessServices" },
  { label: "Charity", value: "Charity" },
  { label: "Consultancy", value: "Consultancy" },
  { label: "Council / Local Authority", value: "CouncilLocalAuthority" },
  { label: "Construction", value: "Construction" },
  { label: "Delivery Company", value: "DeliveryCompany" },
  { label: "Energy", value: "Energy" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "EV Charging", value: "EVCharging" },
  { label: "Finance", value: "Finance" },
  { label: "Food & Drink", value: "FoodDrink" },
  { label: "Fire Services", value: "FireServices" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Leisure", value: "Leisure" },
  { label: "Legal", value: "Legal" },
  { label: "Leasing", value: "Leasing" },
  { label: "Logistics", value: "Logistics" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Media", value: "Media" },
  { label: "Police Fleet", value: "PoliceFleet" },
  { label: "Real Estate", value: "RealEstate" },
  { label: "Renewables", value: "Renewables" },
  { label: "Retail", value: "Retail" },
  { label: "Services", value: "Services" },
  { label: "SME", value: "SME" },
  { label: "Software", value: "Software" },
  { label: "Technology", value: "Technology" },
  { label: "Telecommunications", value: "Telecommunications" },
  { label: "Transportation", value: "Transportation" },
  { label: "Utilities", value: "Utilities" },
  { label: "Wholesale", value: "Wholesale" },
  { label: "Other", value: "Other" },
];

export const ukCountiesOptions = [
  { label: "Please select your county", value: "" },
  { label: "Aberdeenshire", value: "Aberdeenshire" },
  { label: "Angus", value: "Angus" },
  { label: "Argyll and Bute", value: "Argyll-and-Bute" },
  { label: "Bedfordshire", value: "Bedfordshire" },
  { label: "Berkshire", value: "Berkshire" },
  { label: "Blaenau Gwent", value: "Blaenau-Gwent" },
  { label: "Bridgend", value: "Bridgend" },
  { label: "Bristol", value: "Bristol" },
  { label: "Buckinghamshire", value: "Buckinghamshire" },
  { label: "Caerphilly", value: "Caerphilly" },
  { label: "Cambridgeshire", value: "Cambridgeshire" },
  { label: "Cardiff", value: "Cardiff" },
  { label: "Carmarthenshire", value: "Carmarthenshire" },
  { label: "Ceredigion", value: "Ceredigion" },
  { label: "Cheshire", value: "Cheshire" },
  { label: "Clackmannanshire", value: "Clackmannanshire" },
  { label: "Conwy", value: "Conwy" },
  { label: "Cornwall", value: "Cornwall" },
  { label: "Cumbria", value: "Cumbria" },
  { label: "Denbighshire", value: "Denbighshire" },
  { label: "Derbyshire", value: "Derbyshire" },
  { label: "Devon", value: "Devon" },
  { label: "Dorset", value: "Dorset" },
  { label: "Dumfries and Galloway", value: "Dumfries-and-Galloway" },
  { label: "Dundee", value: "Dundee" },
  { label: "Durham", value: "Durham" },
  { label: "East Ayrshire", value: "East-Ayrshire" },
  { label: "East Dunbartonshire", value: "East-Dunbartonshire" },
  { label: "East Lothian", value: "East-Lothian" },
  { label: "East Renfrewshire", value: "East-Renfrewshire" },
  { label: "East Riding of Yorkshire", value: "East-Riding-of-Yorkshire" },
  { label: "East Sussex", value: "East-Sussex" },
  { label: "Edinburgh", value: "Edinburgh" },
  { label: "Essex", value: "Essex" },
  { label: "Falkirk", value: "Falkirk" },
  { label: "Fife", value: "Fife" },
  { label: "Flintshire", value: "Flintshire" },
  { label: "Glasgow", value: "Glasgow" },
  { label: "Gloucestershire", value: "Gloucestershire" },
  { label: "Greater London", value: "Greater-London" },
  { label: "Greater Manchester", value: "Greater-Manchester" },
  { label: "Gwynedd", value: "Gwynedd" },
  { label: "Hampshire", value: "Hampshire" },
  { label: "Herefordshire", value: "Herefordshire" },
  { label: "Hertfordshire", value: "Hertfordshire" },
  { label: "Highland", value: "Highland" },
  { label: "Inverclyde", value: "Inverclyde" },
  { label: "Isle of Anglesey", value: "Isle-of-Anglesey" },
  { label: "Isle of Wight", value: "Isle-of-Wight" },
  { label: "Kent", value: "Kent" },
  { label: "Lancashire", value: "Lancashire" },
  { label: "Leicestershire", value: "Leicestershire" },
  { label: "Lincolnshire", value: "Lincolnshire" },
  { label: "Merseyside", value: "Merseyside" },
  { label: "Merthyr Tydfil", value: "Merthyr-Tydfil" },
  { label: "Midlothian", value: "Midlothian" },
  { label: "Monmouthshire", value: "Monmouthshire" },
  { label: "Moray", value: "Moray" },
  { label: "Neath Port Talbot", value: "Neath-Port-Talbot" },
  { label: "Newport", value: "Newport" },
  { label: "Norfolk", value: "Norfolk" },
  { label: "North Ayrshire", value: "North-Ayrshire" },
  { label: "North Lanarkshire", value: "North-Lanarkshire" },
  { label: "North Somerset", value: "North-Somerset" },
  { label: "North Yorkshire", value: "North-Yorkshire" },
  { label: "Northamptonshire", value: "Northamptonshire" },
  { label: "Northumberland", value: "Northumberland" },
  { label: "Nottinghamshire", value: "Nottinghamshire" },
  { label: "Orkney Islands", value: "Orkney-Islands" },
  { label: "Oxfordshire", value: "Oxfordshire" },
  { label: "Pembrokeshire", value: "Pembrokeshire" },
  { label: "Perth and Kinross", value: "Perth-and-Kinross" },
  { label: "Powys", value: "Powys" },
  { label: "Renfrewshire", value: "Renfrewshire" },
  { label: "Rhondda Cynon Taf", value: "Rhondda-Cynon-Taf" },
  { label: "Rutland", value: "Rutland" },
  { label: "Scottish Borders", value: "Scottish-Borders" },
  { label: "Shetland Islands", value: "Shetland-Islands" },
  { label: "Shropshire", value: "Shropshire" },
  { label: "Somerset", value: "Somerset" },
  { label: "South Ayrshire", value: "South-Ayrshire" },
  { label: "South Gloucestershire", value: "South-Gloucestershire" },
  { label: "South Lanarkshire", value: "South-Lanarkshire" },
  { label: "South Yorkshire", value: "South-Yorkshire" },
  { label: "Staffordshire", value: "Staffordshire" },
  { label: "Stirling", value: "Stirling" },
  { label: "Suffolk", value: "Suffolk" },
  { label: "Surrey", value: "Surrey" },
  { label: "Swansea", value: "Swansea" },
  { label: "Torfaen", value: "Torfaen" },
  { label: "Tyne and Wear", value: "Tyne-and-Wear" },
  { label: "Vale of Glamorgan", value: "Vale-of-Glamorgan" },
  { label: "Warwickshire", value: "Warwickshire" },
  { label: "West Dunbartonshire", value: "West-Dunbartonshire" },
  { label: "West Lothian", value: "West-Lothian" },
  { label: "West Midlands", value: "West-Midlands" },
  { label: "West Sussex", value: "West-Sussex" },
  { label: "West Yorkshire", value: "West-Yorkshire" },
  { label: "Western Isles", value: "Western-Isles" },
  { label: "Wiltshire", value: "Wiltshire" },
  { label: "Worcestershire", value: "Worcestershire" },
  { label: "Wrexham", value: "Wrexham" },
  { label: "York", value: "York" },
];
