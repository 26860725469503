import React from "react";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { signOut } from "../../redux/user/user.actions";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import Button from "../button/Button";

const Header = ({ currentUser, _signOut }) => {
  const navigate = useNavigate();
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
  });

  const signOutNow = () => {
    const user = userPool.getCurrentUser();
    if (user || currentUser) {
      _signOut();
      user.signOut();
      navigate("/");
    }
  };

  if (currentUser !== null) {
    return (
      <header className="header">
        <div className="container">
          <Button
            label={"Go to Profile"}
            isSubmit={false}
            onClick={() =>
              navigate(`/profile/${currentUser.idToken.payload.sub}`)
            }
          />
          <Button label={"Sign out"} isSubmit={false} onClick={signOutNow} />
        </div>
      </header>
    );
  } else {
    return (
      <header className="header">
        <div className="container">
          <Button className="show-mobile" label={"Get Started"} onClick={() => navigate("/submit-entry")} />
          {/* <Button label={"Sign In"} onClick={() => navigate("/auth")} /> */}
        </div>
      </header>
    );
  }
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  _signOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
