import React from "react";
import Tooltip from "../tooltip/ToolTip";
import Info from "../../assets/info.png";
import "./InputField.scss";

const InputField = ({
  handleChange,
  value,
  label,
  tooltip,
  tooltipText,
  onHover,
  onLeave,
  isVisible,
  setVisible,
  ...otherProps
}) => {
  if (tooltip) {
    return (
      <div className="input-field">
        <div className="label-with-tooltip">
          <label htmlFor={otherProps.id}>{label}</label>{" "}
          <div className="tooltip-container">
            <img
              src={Info}
              alt="info-tooltip"
              width={15}
              onMouseEnter={onHover}
              onMouseLeave={onLeave}
            />
            {isVisible && <div className="tooltip">{tooltipText}</div>}
          </div>
        </div>
        <input value={value} {...otherProps} onChange={handleChange} required />
      </div>
    );
  } else {
    return (
      <div className="input-field">
        <label htmlFor={otherProps.id}>{label}</label>{" "}
        <input value={value} {...otherProps} onChange={handleChange} required />
      </div>
    );
  }
};

export default InputField;
