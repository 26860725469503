import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { selectCurrentUser } from './redux/user/user.selectors';
import { connect } from 'react-redux';

import Home from './pages/Home/Home';
import Auth from './pages/Auth/Auth';
import Profile from './pages/Profile/Profile';
import SignUp from './pages/SubmitEntry/SubmitEntry';

import Protected from './Protected';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

function App({currentUser }) {

  const isLoggedIn = currentUser ? true : false;

  return (
    <div className="App">
      <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/submit-entry" element={<SignUp />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/profile/:id" element={
           <Protected isLoggedIn={isLoggedIn}>
             <Profile />
           </Protected>
         } 
         />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
    </Router>
    <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps, null)(App);
