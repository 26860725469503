import React, { useEffect, useState } from "react";
import * as AWS from "aws-sdk";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { signOut } from "../../redux/user/user.actions";
import "./Profile.scss";

const Profile = ({ currentUser }) => {
  useEffect(() => {
    fetchData();
  }, []);

  const [userData, setUserData] = useState({});
  const SK = currentUser.idToken.payload.sub;
  const PK = currentUser.idToken.payload["custom:company_name"];

  console.log(SK);
  console.log(PK);

  const docClient = new AWS.DynamoDB.DocumentClient();

  const fetchData = () => {
    var params = {
      TableName: "FleetRaceUsers",
      KeyConditionExpression: "PK = :PK and SK = :SK",
      ExpressionAttributeValues: {
        ":PK": PK,
        ":SK": SK,
      },
    };

    console.log(params);

    docClient.query(params, function (err, data) {
      if (!err) {
        console.log(data);
        setUserData(data.Items[0]);
      } else {
        console.error(err);
      }
    });
  };

  return (
    <div className="profile">
      <div>
        <div className="container">
          <h1>{PK}'s profile</h1>
          

          <div className="form-container">
            <div className="profile-details">
              <div className="company-details">
                <h3 className="subheading">Company Details</h3>

                <div>
                  <p>
                    Company Name: <b>{PK}</b>
                  </p>
                  <p>
                    Industry: <b>{userData && userData.industry}</b>
                  </p>
                  <p>
                    You are a <b>{userData && userData.company_size}</b>
                  </p>
                  <p>
                    Email: <b>{userData && userData.userName}</b>
                  </p>
                </div>
              </div>

              <div className="fleet-details">
                <h3 className="subheading">Fleet Details</h3>

                <div>
                  <h4>Cars (inc. 4x4)</h4>
                  <div>
                    <p>
                      PHEV: <b>{userData && userData.hybrid_cars}</b>
                    </p>
                    <p>
                      BEV: <b>{userData && userData.electric_cars}</b>
                    </p>
                  </div>
                </div>

                <div>
                  <h4>Vans</h4>
                  <div>
                    <p>
                      PHEV: <b>{userData && userData.hybrid_vans}</b>
                    </p>
                    <p>
                      BEV: <b>{userData && userData.electric_vans}</b>
                    </p>
                  </div>
                </div>
                <div>
                  <h4>Other (inc. HGVs)</h4>
                  <div>
                    <p>
                      PHEV: <b>{userData && userData.hybrid_others}</b>
                    </p>
                    <p>
                      BEV: <b>{userData && userData.electric_others}</b>
                    </p>
                  </div>
                </div>
                {/* <VehicleBarGraph
                  petrol_cars={5}
                  petrol_vans={5}
                  petrol_others={6}
                  hybrid_cars={5}
                  hybrid_vans={5}
                  hybrid_others={6}
                  electric_cars={5}
                  electric_vans={5}
                  electric_others={6}
  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  _signOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
