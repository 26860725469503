import React from 'react';
import './Button.scss';

const Button = ({ label, onClick, isSubmit=false, className = "" }) => {
  if (isSubmit){
    return (
        <button type="submit" className={`custom-button ${className}`} onClick={onClick}>
          {label}
        </button>
      );
  } else {
    return (
        <button className={`custom-button ${className}`} onClick={onClick}>
          {label}
        </button>
      );
  }
};

export default Button;
