import React from "react";
import "./SelectField.scss";
import Info from "../../assets/info.png";
const SelectField = ({
  handleChange,
  label,
  options,
  tooltip,
  onHover,
  onLeave,
  isVisible,
  setVisible,
  ...otherProps
}) => {
  if (tooltip) {
    return (
      <div className="select-field">
        <div className="label-with-tooltip">
          <label htmlFor={otherProps.id}>{label}</label>
          <div className="tooltip-container">
            <img
              src={Info}
              alt="info-tooltip"
              width={15}
              onMouseEnter={onHover}
              onMouseLeave={onLeave}
            />
            {isVisible && (
              <div className="tooltip">
                SME definition: As per the Companies Act 2006, we recognise an
                UK SME as a company meets at least two of the following
                criteria: A turnover of no more than £36 million. No more than
                250 employees. Gross assets (balance sheet total) of no more
                than £18 million.
              </div>
            )}
          </div>
        </div>
        <select {...otherProps} onChange={handleChange} required>
          <optgroup>
            {options.map((option, n) => (
              <option key={n} value={option.value} defaultValue={""}>
                {option.label}
              </option>
            ))}
          </optgroup>
        </select>
      </div>
    );
  } else {
    return (
      <div className="select-field">
        <label htmlFor={otherProps.id}>{label}</label>
        <select {...otherProps} onChange={handleChange} required>
          <optgroup>
            {options.map((option, n) => (
              <option key={n} value={option.value} defaultValue={""}>
                {option.label}
              </option>
            ))}
          </optgroup>
        </select>
      </div>
    );
  }
};

export default SelectField;
