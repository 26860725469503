import React, { useState } from "react";
import InputField from "../../components/input-field/InputField";
import "./Auth.scss";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import { setCurrentUser } from "../../redux/user/user.actions";
import { connect } from "react-redux";
import Button from "../../components/button/Button";

const Auth = ({ setCurrentUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        setCurrentUser(data);
        navigate(`/profile/${data.idToken.payload.sub}`);
      },
      onFailure: (err) => {
        console.log("onFailure: ", err);
        alert(err.message);
      },
      newPasswordRequired: (data) => {
        console.log("newPasswordRequired: ", data);
      },
    });
  };

  const showPassword = () => {
    const password = document.getElementById("password");
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };

  return (
    <div id="auth">
      <div className="container">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="login-details">
              <h3>Sign in</h3>
              <div>
                <InputField
                  label={`Email`}
                  type="email"
                  id="email"
                  handleChange={(e) => setEmail(e.target.value)}
                />

                <InputField
                  label={`Password`}
                  type="password"
                  id="password"
                  handleChange={(e) => setPassword(e.target.value)}
                />
                <div className="show-password">
                  <input
                    type="checkbox"
                    id="show-password"
                    name="show-password"
                    onClick={showPassword}
                  />
                  <label htmlFor="show-password">Show Password</label>
                </div>
              </div>
              <Button type="submit" label={"Sign in"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(null, mapDispatchToProps)(Auth);
